
import Vue, { PropType } from 'vue'
import * as t from '@/store/chisai/GCB2/actionTypes'
import TextField from '../../../inputs/TextField.vue'
import TagInput from '../../../inputs/TagInput.vue'
import { ACTIVATION_DESCRIPTION_MAX_LENGTH, ACTIVATION_NAME_MAX_LENGTH } from '../../../../vars/GCB2'
import { getActivationsTags } from '../../../../api/chisai/GCB2'
import { Project } from '../../../../types/main'
import SpinnerLoader from '../../../SpinnerLoader.vue'
import { checkModuleIsActive } from '../../../../helpers/roles'
export default Vue.extend({
    components: { TextField, TagInput, SpinnerLoader },
    props: {
        value: { type: Object },
    },
    data: () => ({
        tagList: [] as string[],
        loading: true,
        nameRules: [
            (v: any) => !!v || 'Обязательно',
            (v: any) =>
                v.length <= ACTIVATION_NAME_MAX_LENGTH || `Меньше ${ACTIVATION_NAME_MAX_LENGTH} символов`,
        ],
        descritipionRules: [
            (v: any) =>
                v.length <= ACTIVATION_DESCRIPTION_MAX_LENGTH ||
                `Меньше ${ACTIVATION_DESCRIPTION_MAX_LENGTH} символов`,
        ],
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        tagsIsActive(): Boolean {
            return checkModuleIsActive(this.project.id, 'gcb2:tasklist:activationTags')
        },
    },
    methods: {
        formInput(e: any) {
            this.$emit('validation-updated', e)
        },
        clear() {
            ;(this.$refs as any).form.resetValidation()
        },
    },
    async created() {
        this.loading = true
        this.tagList = (await getActivationsTags(this.project.id)).data!
        this.loading = false
    },
})
