
import { computed, defineComponent, onBeforeUnmount, PropType, ref } from 'vue'
import { Audience } from '@/types/GCB2'
export default defineComponent({
    props: {
        audience: {
            type: Object as PropType<Audience>,
            required: true,
        },
    },
    components: {},
    setup(props, {}) {
        const onCreated = () => {}
        onCreated()

        return {}
    },
})
