import { render, staticRenderFns } from "./EditActivationCopyButton.vue?vue&type=template&id=ebed8986&scoped=true"
import script from "./EditActivationCopyButton.vue?vue&type=script&lang=ts"
export * from "./EditActivationCopyButton.vue?vue&type=script&lang=ts"
import style0 from "./EditActivationCopyButton.vue?vue&type=style&index=0&id=ebed8986&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebed8986",
  null
  
)

export default component.exports