
import { defineComponent } from 'vue'
import { checkInputIsIntNumberWithMinus } from '../../../../../utils'

export default defineComponent({
    props: { focused: Boolean, allowNegative: Boolean, value: [String, Number] },
    setup(props, { emit }) {
        const update = (e: any) => {
            const val = e.target.value
            emit('input', val)
        }
        return { update, checkInputIsIntNumberWithMinus }
    },
})
