var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content-wrapper"},[_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('div',{staticClass:"content"},[(_vm.loading)?_c('SpinnerLoader'):_c('div',[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","justify":"space-between"}},[_c('span',{staticClass:"dashboard-title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"create-activaton-btns"},[(_vm.checkRole(_vm.project.id, 'gcb2:activation:toogleIsRunning'))?_c('div',{staticClass:"activation-active-switch"},[_c('v-tooltip',{attrs:{"top":"","open-on-hover":true,"min-width":"300px","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{staticClass:"table-active-switch v-input--reverse",attrs:{"hide-details":"","dense":"","disabled":_vm.isRunningSwitchDisabled,"label":_vm.activeSwitchLabel},model:{value:(_vm.activation.isRunning),callback:function ($$v) {_vm.$set(_vm.activation, "isRunning", $$v)},expression:"activation.isRunning"}})],1)]}}],null,false,72436401)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.activationIsActiveTooltipText))])])],1):_vm._e(),_c('v-btn',{staticClass:"white--text ml-12",attrs:{"elevation":"0","color":"main","disabled":_vm.saveButtonDisabled,"loading":_vm.createOrEditLoading},on:{"click":function($event){_vm.automailValidate.isAutomail && !_vm.automailValidate.isValidScripts
                                ? _vm.scrollToTarget()
                                : _vm.mode === _vm.CHANGE_ACTIVATION_TYPES.create
                                ? _vm.createActivation()
                                : _vm.updateActivation()}}},[_vm._v("Сохранить")]),(_vm.mode === _vm.CHANGE_ACTIVATION_TYPES.edit)?_c('v-btn',{staticClass:"ml-4",attrs:{"outlined":"","color":"main","disabled":_vm.saveButtonDisabled,"loading":_vm.createOrEditLoading},on:{"click":function($event){_vm.automailValidate.isAutomail && !_vm.automailValidate.isValidScripts
                                ? _vm.scrollToTarget()
                                : _vm.createActivation(true)}}},[_vm._v("Сохранить как новую")]):_vm._e(),_c('v-btn',{staticClass:"outlined-btn ml-4",attrs:{"outlined":"","loading":_vm.createOrEditLoading},on:{"click":_vm.goToGCB2}},[_vm._v("Отмена")]),(_vm.mode === _vm.CHANGE_ACTIVATION_TYPES.edit)?_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":_vm.deleteActivation}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()],1)]),_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","justify":"space-between"}},[_c('v-expansion-panels',{staticClass:"mt-6",attrs:{"multiple":_vm.panelsIsMultiplie,"disabled":_vm.expandDisabled},model:{value:(_vm.stepsIndexes),callback:function ($$v) {_vm.stepsIndexes=$$v},expression:"stepsIndexes"}},_vm._l((_vm.availableSteps),function(step,i){return _c('v-expansion-panel',{key:step.id,staticClass:"my-2",class:{
                            disabled: !_vm.availableStepsIds.includes(step.id),
                            active: Array.isArray(_vm.activeStepsIndexes) && _vm.activeStepsIndexes.includes(i),
                            highlighted: step.highlighted,
                            'error-step':
                                step.id === 3 &&
                                _vm.automailValidate.isAutomail &&
                                !_vm.automailValidate.isValidScripts &&
                                _vm.automailValidate.isSaveButtonClicked,
                        },attrs:{"id":`step-${step.id}`}},[_c('v-expansion-panel-header',[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","justify":"space-between"}},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('div',{staticClass:"step-circle",class:{ active: _vm.activeStepsIndexes.includes(i) }},[_c('h2',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(i + 1))])]),_c('h2',{staticClass:"font-weight-regular step-label",class:{ active: _vm.activeStepsIndexes.includes(i) }},[_vm._v(" "+_vm._s(step.title)+" ")])]),(_vm.activeStepsIndexes.includes(i))?_c('div',{staticClass:"activation-step-buttons",on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}}},[([2, 3].includes(step.id))?_c('EditActivationCopyButton',{attrs:{"activationsList":_vm.activationsList,"step":step,"loading":_vm.copyLoading},on:{"copy-from-activation":_vm.copyFromActivation}}):_vm._e(),(_vm.mode === _vm.CHANGE_ACTIVATION_TYPES.create)?_c('v-btn',{staticClass:"activation-step-button mr-2",attrs:{"outlined":"","color":"main","small":"","disabled":step.saveBtnDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.saveStep(step, i)}}},[_vm._v("Сохранить")]):_vm._e(),_c('v-btn',{staticClass:"outlined-btn activation-step-button mr-6",attrs:{"outlined":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clear(step)}}},[_vm._v("Очистить")])],1):_vm._e()],1)],1),_c('v-expansion-panel-content',[_c(step.contentComponent,{ref:step.ref,refInFor:true,tag:"component",attrs:{"mode":_vm.mode,"automailValidate":_vm.automailValidate},on:{"validation-updated":function($event){return _vm.updateSaveButton($event, step, i)}},model:{value:(_vm.activation),callback:function ($$v) {_vm.activation=$$v},expression:"activation"}})],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }