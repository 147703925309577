
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import _ from 'lodash'
import { checkInputIsIntNumber } from '@/utils'
export default defineComponent({
    components: {},
    props: {
        items: {
            type: Array as PropType<string[]>,
            required: true,
        },
        value: {
            type: Array as PropType<string[]>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const search = ref<any>(null)
        const update = (val: string[]) => {
            emit('input', val)
            search.value = ''
        }
        const createTag = () => {
            if (search.value) {
                update(props.value.concat(search.value))
                search.value = ''
            }
        }
        return { search, createTag, update }
    },
})
