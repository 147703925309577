
import Vue, { PropType } from 'vue'
import * as t from '@/store/chisai/GCB2/actionTypes'
import TextField from '../../../../inputs/TextField.vue'
import { Audience } from '../../../../../types/GCB2'
import {
    getAudience,
    getServiceByCategoryList,
    getServiceCategoryList,
    getActivationList,
} from '../../../../../api/chisai/GCB2'
import { isBlank, mod } from '@/utils'
import SpinnerLoader from '../../../../SpinnerLoader.vue'
import AudienceTitleWithParams from './AudienceTitleWithParams.vue'
import AudienceListItem from './AudienceListItem.vue'
import _, { omitBy } from 'lodash'
import { getServiceList, getStaffList } from '@/api/chisai/GCB2'
import { checkRole } from '../../../../../helpers/roles'
import { AUDEINCE_ADDITIONAL_SETTINGS } from '../../../../../vars/GCB2'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import Tags from '../../../../inputs/Tags/Tags.vue'
import { Tag } from '@/types/main'
import { TAG_COLORS } from '../../../../../vars/general'
import cfg from '@root/config'
import FileUploader from '../../../../ui/FileUploader.vue'
const FETCH_LIST_FUNCTIONS: any = {
    staff_list: getStaffList,
    service_list: getServiceByCategoryList,
    category_list: getServiceCategoryList,
    activation_list: getActivationList,
}
const xlsMimeTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformatsofficedocument.spreadsheetml.template',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/xls',
  'application/x-xls',
]
export default Vue.extend({
    components: {
        AudienceTitleWithParams,
        TextField,
        SpinnerLoader,
        AudienceListItem,
        BaseInputOutlined,
        Tags,
        FileUploader,
    },
    props: {
        value: { type: Object },
        mode: { type: String },
    },
    data: () => ({
        audienceFilterValues: {} as any,
        loading: true,
        audienceList: [] as Audience[],
        audienceRules: [(v: any) => !!v || 'Обязательно'],
        lists: {} as any,
        selectedTags: [] as string[],
        search: '',
        fromFile: false,
        xlsTypes: xlsMimeTypes
    }),
    computed: {
        projectId(): string {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id).id
        },
        filteredAudienceList(): any[] {
            const titleFilter = (audience: Audience) =>
                this.search
                    ? this.search
                          .trim()
                          .toLowerCase()
                          .split(' ')
                          .every(word => audience.name.toLowerCase().includes(word)) ||
                      String(audience.id)
                          .toLowerCase()
                          .includes(this.search.trim().toLowerCase())
                    : true
            const tagFilter = (audience: Audience) =>
                this.selectedTags.every(tag => audience.tags.includes(tag))
            return this.audienceList.filter(el => titleFilter(el) && tagFilter(el))
        },
        tagsList(): Tag[] {
            return _.uniq(this.audienceList.flatMap(el => el.tags)).map((tagName, i) => ({
                id: tagName,
                name: tagName,
                color: TAG_COLORS[mod(i, TAG_COLORS.length)],
            }))
        },
        selectedAudience(): Audience {
            return this.audienceList.find(el => el.id === this.value.audienceId) as Audience
        },
    },
    watch: {
        'value.audienceId'() {
            this.setFilter(this.value.audienceId)
        },
        audienceFilterValues: {
            deep: true,
            handler() {
                this.setFilter(this.value.audienceId)
            },
        },
    },
    methods: {
        checkRole,
        formInput(e: any) {
            const isValid = this.fromFile ? this.value.excelFile?.file : !_.isNil(this.value.audienceId)
            this.$emit('validation-updated', isValid)
        },
        clear() {
            ;(this.$refs as any).form.resetValidation()
        },
        selectAudience(e: Audience | null) {
            this.value.audienceId = e?.id
            this.fromFile = cfg.excelActivationId === e?.id
        },
        //TODO: Можно упросить в связи с новым дизайном
        initAudienceFilterValues() {
            const audienceFilterValues = {} as any
            this.audienceList.forEach((audience: Audience) => {
                const filterValue = {} as any
                const schema = audience.settings.schema
                const additionalParamsList = audience.settings.additional_params_list
                if (schema) {
                    schema.flat().forEach(el => {
                        if (el.valueField) {
                            const value =
                                this.value.audienceId === audience.id
                                    ? this.value.extra?.filter[el.valueField] ?? el.defaultValue
                                    : el.defaultValue
                            filterValue[el.valueField] = value
                        }
                    })
                }
                if (additionalParamsList) {
                    additionalParamsList.forEach((param: string) => {
                        const defautValue = _.get(AUDEINCE_ADDITIONAL_SETTINGS, `${param}.defaultValue`)
                        filterValue[param] =
                            this.value.audienceId === audience.id
                                ? this.value.extra?.filter[param] ?? defautValue
                                : defautValue
                    })
                }
                if (this.value.audienceId === audience.id) {
                    Object.assign(filterValue, this.value.extra?.filter)
                }
                audienceFilterValues[audience.id] = filterValue
            })
            this.audienceFilterValues = audienceFilterValues
        },
        setFilter(audienceId: number) {
            const val = _.mapValues(this.audienceFilterValues[audienceId], o => (isBlank(o) ? null : o))
            const audience = this.audienceList.find(el => el.id === audienceId)
            const filterDefaultValue = audience?.settings?.parameterized ? {} : null
            const filter = _.isEmpty(val) ? filterDefaultValue : omitBy(val, _.isNil)
            this.value.extra = Object.assign({}, this.value.extra, { filter })
        },
        async fetchLists() {
            const listsToFetch = _.uniq(
                this.audienceList
                    .flatMap(el => el.settings.schema?.flat())
                    .filter(el => el)
                    .map(el => (el?.type === 'select' ? el.list : null))
                    .filter(el => el)
            )
            return Promise.all(
                listsToFetch.map(async (el: any) => {
                    const fetch = FETCH_LIST_FUNCTIONS[el]
                    if (fetch) {
                        this.lists[el] = await fetch({ projectId: this.projectId }).then((el: any) => el.data)
                    }
                })
            )
        },
        showAudienceAdditionalParams(audience: any) {
            return audience.settings.additional_params_list && audience.id === this.value.audienceId
        },
    },
    async created() {
        this.loading = true
        this.audienceList = await getAudience(this.projectId)
        await this.fetchLists()
        this.initAudienceFilterValues()
        this.loading = false
        this.value.excelFile = {}
        this.fromFile = cfg.excelActivationId === this.value.audienceId
    },
    mounted() {
        this.$emit('validation-updated', !_.isNil(this.value.audienceId))
    },
})
