
import { defineComponent, PropType} from 'vue'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
import { EditedActivation } from '../../../../types/GCB2'
import { useProject } from '../../../../hooks/useProject'
import SpinnerLoader from '../../../SpinnerLoader.vue'
import { v4 as uuidv4 } from 'uuid'
export default defineComponent({
    name: 'CommunicationTagSettings',
    components: { BaseInputOutlined, CustomActivatorSelect, SpinnerLoader },
    props: {
        value: { type: Object as PropType<EditedActivation>, required: true },
    },
    setup(props, { emit }) {
        const { project } = useProject()

        const addTag = () => {
            props.value.communicationTags.push({ id: uuidv4(), name: '', projectId: project.value.id })
        }
        const removeCommunicationTag = (tagId: string) => {
            props.value.communicationTags = props.value.communicationTags.filter(el => el.id !== tagId)
        }
        return { addTag, removeCommunicationTag }
    },
})
