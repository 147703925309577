
import Vue, { defineComponent, onMounted, ref } from 'vue'
import BaseInputOutlined from '@root/src/components/inputs/BaseInputOutlined.vue'
import phoneValidator from '../../../../helpers/validators/phoneValidator'
import ValidationInput from '../../../../components/inputs/ValidationInput.vue'

export default Vue.extend({
    components: { BaseInputOutlined, ValidationInput },
    data: () => ({
        warn: false,
        testModePhone: '',
        mode: '',
    }),
    props: {
        value: { type: Object },
    },
    watch: {
        warn(v) {
            if (!this.warn) {
                this.value.testModePhone = this.testModePhone.replace('+', '')
            } else {
                this.value.testModePhone = ''
            }
        },
    },
    methods: {
        onChange() {
            this.mode = this.value.mode
        },
        checkWarn(e) {
            this.warn = e
        },
        change(e) {
            console.log(e)
        },
    },
    setup() {
        return {
            phoneValidator,
        }
    },
    mounted() {
        if (!this.value.mode) {
            this.value.mode = 'Боевой'
        }
        this.mode = this.value.mode
        this.testModePhone = this.value.testModePhone
    },
})
