
import Vue, { PropType } from 'vue'

import { Activation, EditActivationStep } from '@/types/GCB2'

export default Vue.extend({
    components: {},
    props: {
        activationsList: { type: Array as PropType<Activation[]> },
        step: { type: Object as PropType<EditActivationStep> },
        loading: { type: Boolean },
    },
    data: () => ({}),

    methods: {
        copyFromActivation(activation: Activation) {
            this.$emit('copy-from-activation', { step: this.step, activation })
        },
    },
})
