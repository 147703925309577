
import { computed, defineComponent, ref, watch } from 'vue'
import { isNumeric } from '../../../../../utils'
import * as _ from 'lodash'
export default defineComponent({
    props: {
        focused: Boolean,
        value: { type: null, default: () => [] },
        items: { type: Array, default: () => [] },
        itemValue: { type: String, default: 'id' },
        itemName: { type: String, default: 'name' },
        returnObject: Boolean,
        multiple: Boolean,
        width: { type: Number },
        label: { type: String, default: '' },
    },
    setup(props, { emit }) {
        const searchQuery = ref('')
        const menuOpened = ref(false)
        const V_SCROLL_ITEM_HEIGHT = 45
        const V_SCROLL_DEFAULT_HEIGHT = 288
        const selectAllIconColor = 'main'
        const update = (val: any) => {
            emit('input', val)
        }
        watch(menuOpened, val => {
            setTimeout(() => (searchQuery.value = ''), 200)
        })

        const height = computed(
            () => _.clamp(filteredItems.value.length * V_SCROLL_ITEM_HEIGHT, 0, V_SCROLL_DEFAULT_HEIGHT) + 24
        )
        const filteredItems = computed(() =>
            props.items.filter((v: any) =>
                searchQuery.value ? v[props.itemName].match(new RegExp(searchQuery.value, 'i')) : props.items
            )
        )
        const selectedAll = computed(() => props.value.length === props.items?.length)
        const inputValue = computed(() =>
            props.multiple
                ? `${props.value.length} ${props.label ? `(${props.label})` : ''}`
                : (props.items.find((el: any) => el[props.itemValue] === props.value) as any)?.[
                      props.itemName
                  ]
        )
        const selectAllIcon = computed(() => {
            if (selectedAll.value) return 'mdi-checkbox-outline'
            if (props.value.length) return 'mdi-minus-box-outline'
            return 'mdi-checkbox-blank-outline'
        })
        const select = (item: any) => {
            if (props.multiple) {
                const val = !props.value.includes(item[props.itemValue])
                if (val) {
                    update(props.value.concat(item[props.itemValue]))
                } else {
                    update(props.value.filter((el: any) => el !== item[props.itemValue]))
                }
            } else {
                update(item[props.itemValue])
            }
        }

        const selectAll = () => {
            if (props.value.length) {
                update([])
            } else {
                update(props.items.map((el: any) => el[props.itemValue]))
            }
        }
        return {
            update,
            searchQuery,
            menuOpened,
            select,
            selectAll,
            selectAllIcon,
            selectAllIconColor,
            filteredItems,
            inputValue,
            V_SCROLL_ITEM_HEIGHT,
            height,
        }
    },
})
