
import { defineComponent, computed, ref } from 'vue'
import AudienceSettingsBaseInput from '@/components/inputs/AudienceSettingsBaseInput.vue'
import moment from 'moment'
export default defineComponent({
    props: { focused: Boolean, value: [String, Number] },
    components: { AudienceSettingsBaseInput },
    setup(props, { emit }) {
        const dateMenuOpened = ref<boolean>(false)
        const formattedText = computed(() => (props.value ? moment(props.value).format('DD.MM.YYYY') : ''))
        const update = (val: any) => {
            emit('input', val)
        }

        return { dateMenuOpened, formattedText, update }
    },
})
