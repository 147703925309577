
import {
    defineComponent,
    ref,
    PropType,
    computed,
    shallowRef,
    watch,
    onMounted,
    getCurrentInstance,
} from 'vue'
import useRouter from '../../../../hooks/useRouter'
import { Project } from '../../../../types/main'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
import TimeInput from '../../../inputs/TimeInput.vue'
import { EditedActivation } from '../../../../types/GCB2'
import { timezones } from '@/vars/timezones'
import { checkInputIsIntNumber } from '../../../../utils'
import DatePicker from '../../../ui/DatePicker.vue'
import moment from 'moment-timezone'
import project from '@root/src/store/project'
import { useProject } from '@root/src/hooks/useProject'
export default defineComponent({
    name: 'AutomailSettings',
    components: { BaseInputOutlined, CustomActivatorSelect, TimeInput, DatePicker },
    props: {
        value: { type: Object as PropType<EditedActivation>, required: true },
        automailValidate: {
            type: Object,
            required: false,
        },
    },
    computed: {
        project() {
            const { project } = useProject()
            return project
        },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { route } = useRouter(root)
        const project = computed<Project>(() => root.$store.getters.projectById(route.value.params.id))
        const currentValue = ref(props.value.extra.automail.isDeleted)

        const waBtnDisabled = computed(() => {
            const whatsappStatus = root.$store.state.whatsappStatus
            if (!whatsappStatus || whatsappStatus?.statusSubsystem !== 'on') return false

            const btnStatus = whatsappStatus.aliveStatus === 'dead' ? true : false
            return btnStatus
        })

        if (waBtnDisabled.value) {
            currentValue.value = true
        }

        const currentTimezoneOffsetInHours = computed(() => {
            const currentDate = new Date()
            const timezoneOffsetMinutes = currentDate.getTimezoneOffset()
            const timezoneOffsetHours = (timezoneOffsetMinutes / 60) * -1
            return timezoneOffsetHours
        })

        const timezonesList = timezones.map(el => ({
            id: parseInt(el.offset.replace(/[^0-9]/g, '')),
            name: el.cities.length ? `${el.timezone} (${el.cities.slice(0, 3).join(', ')})` : el.timezone,
            recomended: currentTimezoneOffsetInHours.value === Number(el.offset),
        }))

        const formInput = (e: any) => {
            emit('validation-updated', e)
        }
        const requiredRules = computed(() =>
            props.value.extra.automail?.isDeleted ? [] : [(v: any) => !!v || 'Обязательно']
        )
        const intervalInMinutesRules = computed(() =>
            props.value.extra.automail?.isDeleted
                ? []
                : [(v: any) => !!v || 'Обязательно', (v: any) => Number(v) >= 15 || 'Должно быть больше 15']
        )
        const timeRules = computed(() =>
            props.value.extra.automail?.isDeleted
                ? []
                : [(v: any) => !!v || 'Обязательно', (v: any) => v?.length === 5 || 'Обязательно']
        )
        const onSwitchChange = () => {
            props.automailValidate.isAutomail = !props.value.extra.automail.isDeleted
        }
        onMounted(() => {
            emit('validation-updated', true),
                (props.automailValidate.isAutomail = !props.value.extra.automail.isDeleted)
        })
        return {
            currentValue,
            formInput,
            timezonesList,
            checkInputIsIntNumber,
            requiredRules,
            intervalInMinutesRules,
            timeRules,
            waBtnDisabled,
            onSwitchChange,
        }
    },
})
