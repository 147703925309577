
import Vue, { PropType } from 'vue'

import { Activation, EditActivationStep, MessagePart } from '@/types/GCB2'
import _ from 'lodash'

export default Vue.extend({
    components: {},
    props: {
        messageParts: { type: Array as PropType<MessagePart[]> },
        step: { type: Object as PropType<EditActivationStep> },
    },
    data: () => ({
        menuIsOpened: false as boolean,
    }),
    computed: {
        availablePartNumbers(): number[] {
            const maxPartNumber = (_.max(this.messageParts.map(el => el.partNumber)) || 0) as number
            return Array.from({ length: maxPartNumber + 1 } as any, (_, i) => i + 1)
        },
    },
    methods: {
        addMessagePart(partNumber: number) {
            this.$emit('add-message-part', partNumber)
        },
    },
})
