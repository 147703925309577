
import {
    defineComponent,
    PropType,
} from 'vue'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
import TimeInput from '../../../inputs/TimeInput.vue'
import { EditedActivation } from '../../../../types/GCB2'
import { timezones } from '@/vars/timezones'
import { checkInputIsIntNumber } from '../../../../utils'
import SpinnerLoader from '../../../SpinnerLoader.vue'
export default defineComponent({
    name: 'AutomailSettings',
    components: { BaseInputOutlined, CustomActivatorSelect, TimeInput, SpinnerLoader },
    props: {
        value: { type: Object as PropType<EditedActivation>, required: true },
    },
    setup(props, { emit }) {
        const timezonesList = timezones.map(el => ({
            id: el.offset.replace(/[^0-9]/g, ''),
            name: el.cities.length ? `${el.timezone} (${el.cities.slice(0, 3).join(', ')})` : el.timezone,
        }))
        const formInput = (e: any) => {
            const isValid = true
            emit('validation-updated', isValid)
        }

        return { formInput, timezonesList, checkInputIsIntNumber, set: _.set }
    },
})
