
import { defineComponent } from 'vue'

export default defineComponent({
    props: { width: { type: Number, default: 100 }, focused: Boolean, value: [String, Number] },
    setup(props, { emit }) {
        const update = (e: any) => {
            const val = e.target.value
            emit('input', val)
        }

        return { update }
    },
})
