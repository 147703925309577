
import { defineComponent, ref, PropType, computed, shallowRef, watch, onMounted } from 'vue'
import * as _ from 'lodash'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
import api from '../../../../api/api'

export default defineComponent({
    name: 'MessageCollectionButton',
    components: { CustomActivatorSelect },
    props: {},
    setup(props, { emit }) {
        const messageCollection = ref({ data: [], loading: true })
        const messageCollectionList = computed(() =>
            messageCollection.value.data.map((el: any) => ({ id: el._id, name: el.name }))
        )
        const setMessage = (messageId: string) => {
            const message = messageCollection.value.data.find((el: any) => el._id === messageId)
            emit('set-message', message)
        }
        onMounted(async () => {
            messageCollection.value.loading = true
            const { data, error } = await api.gcb2.getMessageCollection()
            messageCollection.value.data = data!
            messageCollection.value.loading = false
        })

        return { messageCollection, messageCollectionList, setMessage }
    },
})
