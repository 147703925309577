
import Vue, { PropType } from 'vue'
import * as t from '@root/src/store/chisai/GCB2/actionTypes'
import { setChangedActivationNotification, updateActivation } from '../../../../api/chisai/GCB2'
import { Activation } from '../../../../types/GCB2'
import {
    ACTIVATION_LIMIT_TEXT_LIMIT_EXCEEDED,
    ACTIVATION_LIMIT_TEXT_TURN_OFF,
    ACTIVATION_LIMIT_TEXT_TURN_ON,
} from '../../../../vars/GCB2'

export default Vue.extend({
    components: {},
    props: {
        item: { type: Object as PropType<Activation> },
    },
    data: () => ({}),
    computed: {
        disabled(): boolean {
            return this.runningActivationsCount >= this.runningActivationsLimit && !this.isRunning
        },
        runningActivationsCount(): number {
            return this.$store.getters[`GCB2/${t.GET_RUNNING_ACTIVATIONS_COUNT}`](this.item.projectId)
        },
        isRunning(): boolean {
            return this.item.isRunning
        },
        runningActivationsLimit(): number {
            return this.$store.getters[`GCB2/${t.GET_ACTIVATIONS_COUNT_LIMIT}`](this.item.projectId)
        },
        tooltipText(): string {
            if (this.runningActivationsCount >= this.runningActivationsLimit && !this.isRunning) {
                return ACTIVATION_LIMIT_TEXT_LIMIT_EXCEEDED(this.runningActivationsLimit)
            } else if (this.isRunning) {
                return ACTIVATION_LIMIT_TEXT_TURN_OFF
            } else {
                return ACTIVATION_LIMIT_TEXT_TURN_ON
            }
        },
    },

    methods: {
        async updateActivation(e: boolean) {
            const isRunning = e
            this.$store.commit(`GCB2/${t.UPDATE_ONE_ACTIVATION}`, {
                id: this.item.id,
                projectId: this.item.projectId,
                isRunning: isRunning,
            })
            const response = await updateActivation({
                id: this.item.id,
                projectId: this.item.projectId,
                isRunning: isRunning,
            })
            if (response.error) {
                this.$store.dispatch('callNotify', 'Ошибка')
                return
            }
            const activation = response.data
            this.$store.commit(`GCB2/${t.UPDATE_ONE_ACTIVATION}`, activation)
            await setChangedActivationNotification({
                projectId: this.item.projectId,
                active: true,
            })
        },
    },
})
